import {
  logEvent,
  setUserProperties,
} from "firebase/analytics";
import {
  getIdToken,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";

import activebuildconfig from "../../configs/activebuildconfig.json";
const APIRootUrl = activebuildconfig.API_ROOT_URL;

import { firebaseAuth, firebaseAnalytics } from "../../firebase";

import version from "../../version/version.json";

import { conditionallyLog } from "./conditionallyLog";


// Helper to take care of endpoint boilerplate code
import { asyncFetchResult } from "./asyncFetchResult";

/**
 * Send a user a password reset email for the associated account with a given email and pass a "success" or "fail" message to the callback
 *
 * @param {string} email - user email that is used for login and Drill sign up (immutable)
 * @param {function} callback - callback function
 * 
 */
export function forgotPassword(email, callback) {
  sendPasswordResetEmail(firebaseAuth, email)
    .then(() => {
      // Email sent.
      callback("success");
      console.log("reset email sent");
    })
    .catch((error) => {
      // Handle Errors here.
      callback("fail");
      console.log("error", error);
    });
}

/**
 * Sign in a user based on email and password, sets the user's persistence to be local
 * (meaning they will stay logged in if they close the browser), 
 * and passes a boolean success flag and optional error message to the callback
 *
 * @param {string} email - user email that is used for login and Drill sign up (immutable)
 * @param {string} password - password for user account (mutable)
 * @param {function} callback - callback function
 * 
 */
export function signInFunc(email, password, callback) {
  console.log("sign in user called with sign-in:", email);
  signInWithEmailAndPassword(firebaseAuth, email, password)
    .then(() => {
      localStorage.setItem("signUpSucceeded", true);
      callback(true, null);
    })
    .catch((error) => {
      callback(false, error);
      console.log("error in sign-in");
    });
}

/**
 * Attempt to log in a user based on email and password, sets the user's persistence to be local
 * (meaning they will stay logged in if they close the browser), 
 * and passes a boolean success flag and optional error message to the callback
 *
 * @param {string} email - user email that is used for login and Drill sign up (immutable)
 * @param {string} password - password for user account (mutable)
 * @param {function} callback - callback function
 * 
 */
export function attemptLogin(email, password, callback) {
  console.log("attemptLogin with email:", email);

  // first authenticate the user
  signInWithEmailAndPassword(firebaseAuth, email, password)
    .then(() => {
      checkEmail(email, null, callback);
    })
    .catch(() => {
      // authentication error
      callback(false, "auth/user-not-found");
    });
}

export function checkEmail(email, utmData, callback) {
  // if successfully authenticated, then authorize
  fetch(`${APIRootUrl}/speak/check_email`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      loginEmail: email,
      version: version.version,
      userAgent: window.navigator.userAgent,
      utmData,
    }),
  })
    .then((res) => {
      const output = res;

      if (res.status === 200) { 
        output.json()
          .then((data) => {
            callback(data.data);
          })
          .catch(() => {
            // authorization error
            callback(false);
          });
      }
      else {
        callback(false);
      }
    })
    .catch(() => {
      // authorization error
      callback(false);
    });
}

/**
 * Sign up a user with initial information of email, password, first name, last name, and level and 
 * passes either a "success" message or error message to callback function
 *
 * @param {function} callback - callback function
 * @param {string} email - user email that is used for login and Drill sign up (immutable)
 * @param {string} password - password for user account (mutable)
 * @param {string} firstName - user first name (mutable)
 * @param {string} lastName - user last name (mutable)
 * @param {integer} level - user level based off response from sign up page (mutable)
 * @param { String } languageTarget - language the user wants to learn. From this, we infer the language the user speaks
 */
export function signUpUser(
  callback,
  email,
  password,
  firstName,
  lastName,
  level,
  languageTarget,
  utmData,
){
  fetch(`${APIRootUrl}/signUp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
      firstName,
      lastName,
      level,
      languageTarget,
      userAgent: window.navigator.userAgent,
      utmData,
    }),
  })
    .then((res) => {
      res.json()
        .then((data) => {
          // Get credential info and idHash of ongoing lesson from server response
          const { cred } = data;

          // If we got credentials, update local auth and return success
          if (cred !== null && cred !== undefined) {

            // TODO: centralize for use elsewhere
            // rudimentary logging of Google Analytics Event
            logEvent(firebaseAnalytics, "new_speak_account_created");
            setUserProperties(firebaseAnalytics, { user_name: email });
          
            // Invoke callback with success flag
            callback({
              message: "success",
            });
          }
          else {
            const errorMessage = data.error;
            callback(errorMessage);
          }
        });
    })
    .catch((error) => {
      callback(error);
    });
}

/**
 * Get partial student details given a list of fields we want
 *
 * @param {Array} fields (optional) fields to return from student file
 * @returns Object with student details
 */
export async function getOneStudentPartial(fields) {

  // The get_one_student endpoint relies on token sent from a logged-in user on the front end to find the student data in the database. Therefore, if not logged in, we'll get a 401 from the server. Avoid the error by not making the call if we don't have a logged-in user!
  if ( !firebaseAuth.currentUser ) {
    // Return empty object to signify no user to callers
    return {};
  }

  // If we do have a logged-in user, get the requested info
  try {
    const result = await asyncFetchResult({
      endpoint: "get_one_student",
      body: { fields },
    });

    return result.data;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not get one student partial data",
    });
    return false;
  }
}

/**
 * Get completed lesson file details for list of lessons student has attended
 *
 * @param {integer} startIndex (optional) start index if we want a subset of student lessons (inclusive)
 * @param {integer} endIndex (optional) end index if we want a subset of student lessons (exclusive)
 * @param {Array} fields (optional) fields to return from student file
 * @returns Array with lesson objects filtered by the fields array
 */
export async function getCompletedLessonsFromStudentId(startIndex = undefined, endIndex = undefined, fields) {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;
    
    const body = {
      token,
      startIndex, 
      endIndex,
      fields,
    };

    const response = await fetch(`${APIRootUrl}/speak/get_completed_lessons_from_student_id`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const responseBody = await response.json();
    return responseBody.data;
  }
  catch (error) {
    conditionallyLog({
      error,
      message: "Could not get completed lessons by student id",
    });
    return false;
  }
}

/**
 * Get partial lesson file details for list of upcoming lessons student is attending
 *
 * @param {Array} fields (optional) fields to return from student file
 * @returns Array with lesson objects filtered by the fields array
 */
export async function getLessonsFromStudentId(fields) {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    const body = {
      token,
      fields,
    };

    const response = await fetch(`${APIRootUrl}/speak/get_lessons_from_student_id`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const responseBody = await response.json();
    return responseBody.data;
  }
  catch (error) {
    conditionallyLog({
      error,
      message: "Could not get lessons by student id",
    });
    return false;
  }
}

/**
 * Get array of privileges given an organization id
 *
 * @param {String} organizationId organization id
 * @returns Array with string ENUMs corresponding to student privileges
 */
export async function getStudentPrivileges(organizationId) {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    const body = {
      token,
      organizationId,
    };

    const response = await fetch(`${APIRootUrl}/speak/get_student_privileges`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const responseBody = await response.json();
    return responseBody.data.studentPrivileges;
  }
  catch (error) {
    conditionallyLog({
      error,
      message: "Could not get student privileges by group id",
    });
    return false;
  }
}

/**
 * Updates a student file's first name, last name, phone number on backend and passes a true/false boolean to the callback function that is true on success
 *
 * @param {function} callback - callback function
 * @param {String} uid - user id of student (currently user email)
 * @param {String} firstName - first name input from student
 * @param {String} lastName - last name input from student
 * @param {phoneNumber} phoneNumber - phone input from student
 * 
 */
export function updateStudent(callback, uid, firstName, lastName, phoneNumber) {
  getIdToken(firebaseAuth.currentUser).then((idToken) => {
    // Send token to your backend via HTTPS
    // ...
    fetch(`${APIRootUrl}/updatestudent`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token: idToken,
        id: uid,
        firstName: firstName,
        lastName: lastName, 
        phoneNumber: phoneNumber,
      }),
    }).then((response) => { 
      if (response.status === 200) {
        callback(true);
      }
      else { 
        callback(false);
      }
    }).catch(()=> { 
      callback(false);
    });
  });
}

/**
 * Get partial module details given a module ID
 *
 * @param {string} moduleId id of module
 * @param {Boolean} getVocab flag if module's vocabulary should also be retrieved, default is true
 * @returns Object with module details
 */
export async function getOneModule(moduleId, getVocab = true) {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    // define the fields from module that should be returned, including hasVocabulary
    // vocabulary field does not need to be specified here, it will always be returned if getVocab is true
    // TODO: Deprecate this hard-coded fields list -- it's easy to forget to maintin this as fields change on modules, and means that if one component needs more/less info from the module, we'd have to make a new datastore function
    const fields = [ "category", "difficulty", "hasVocabulary", "id", "language", "lesson_name", "quizlet_url", "slidesDotComURL", "unit_name", "unit_number", "spotifyURL", "transcriptURL", "youtubeURL", "topicCardImage", "isComingSoon" ];
    const body = {
      token,
      moduleId,
      getVocab,
      fields,
    };

    const response = await fetch(`${APIRootUrl}/speak/get_one_module`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const responseBody = await response.json();
    return responseBody.data;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not fetch one module",
    });
    // Throwing an error because Study.js relies on error bubbling up through selectModuleById
    throw new Error(`Error fetching one module: ${ error }`);
  }
}

/**
 * Updates one student module document
 * 
 * @param {String} moduleId - moduleId of document to update
 * @param {Boolean} onWishlist - whether the module should be on or off the wish list
 * @returns Object with success flag
 */
export async function updateStudentModule(moduleId, onWishlist) {
  try {
    const body = {
      moduleId,
      onWishlist,
    };

    const result = await asyncFetchResult({
      endpoint: "update_student_module",
      body: body,
    });

    // Return data we fetched
    return result.data;
  }
  catch (error) {
    conditionallyLog({
      error,
      message: "Could not update student wishlist for module",
    });
    return false;
  }
}

/**
 * Gets an array of all teachers' preferred_name field and passes the array to a callback function
 *
 * @param {function} callback - callback function
 * 
 */
export function getTeachers(callback) { 
  getIdToken(firebaseAuth.currentUser).then((idToken) => {
    // Send token to your backend via HTTPS
    // ...
    fetch(`${APIRootUrl}/speak/get_all_teachers`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token: idToken,
        fields: "preferred_name", // preferred_name is all that's used in SP
      }),
    })
      .then((res) => {
        const output = res;
        output.json()
          .then((data) => {
            callback(data.data);
          });
      })
      .catch((error) => {
        console.log("error getting teachers", error);
        callback(false);
      });
  }).catch((error) => {
    // Handle error
    console.log("error getting id token", error);
    callback(error);
  });
}

/**
 * Get partial module details given a module ID
 *
 * @param {string} startTimeUTC - start date and time with UTC offset 0 (format: YYYY-MM-DDTHH:mm:ss.sssZ)
 * @param {string} endTimeUTC - end date and time with UTC offset 0 (format: YYYY-MM-DDTHH:mm:ss.sssZ)
 * @param {string} level - level for filtering in format of a digit (NOT L1, L2, etc)
 * @param {string} lessonType - lessonType for filtering
 * @param {string} instructor - (optional) instructor for filtering in format of instructor name or "all" 
 * @returns Array of lesson objects
 */
export async function getLessonsForBooking(startTimeUTC, endTimeUTC, level = 2, lessonType, instructor) {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    // define the fields from lessons that should be returned
    const fields = ["lessonId", "date", "time", "level", "category", "title", "instructor", "isRegistered", "joinURL", "module", "showEnabled", "lessonScheduledStartUTC", "lessonScheduledEndUTC", "idHash", "cutoffBookingAsSpeakerMinutes", "registrationTreatmentType", "allowObservers"];
    const body = {
      token,
      startTimeUTC,
      endTimeUTC,
      level, 
      lessonType, 
      instructor,
      fields,
    };

    const response = await fetch(`${APIRootUrl}/speak/get_lessons_for_booking`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const responseBody = await response.json();

    // check for success flag before returning response data back
    if (responseBody.success) {
      return responseBody.data;
    }
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not get lessons for booking",
    });
    return false;
  }
}

export const asyncGetFeedbackForModule = async (moduleId) => {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    // Dispatch request to cloud functions
    const response = await fetch(`${APIRootUrl}/speak/get_feedback_for_module`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        moduleId,
      }),
    });
  
    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const result = await response.json();
  
    // If we get a failure flag or don't have data, something is wrong
    if (!result.success || !result.data) {
      throw new Error("No success flag in return, or no data");
    }
    
    return result.data;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not get feedback for module",
    });
    throw new Error(`failed to get feedback for ${moduleId}`);
  }
};

/**
 * Gets a valid token for use when joining a Daily room
 * 
 * @param { String } lessonId - Of the lesson the student is joining
 * @param { String } lessonTreatmentType - Lesson treatment type for student
 * @param { String } roomName - Name of Daily room with which the token will be
 * associated
 * @returns the token
 */
export const asyncGetDailyToken = async ({
  lessonId,
  lessonTreatmentType,
  roomName,
}) => {
  try {

    // Fetch token info from back end
    const result = await asyncFetchResult({
      endpoint: "get_daily_token",
      body: {
        lessonId,
        lessonTreatmentType,
        roomName,
      },
    });

    if ( !result.success || !result.data ) {
      throw new Error("No success flag in return, or no data");
    }

    return result.data;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not get Daily token due",
    });
    return false;
  }
};

/**
 * Record userAgent and other studentLesson-specific info in Firestore
 * 
 * @param { String } lessonId - of Drill being joined
 * @param { String } treatmentType - SPEAKER or OBSERVER, depending on
 * registration and health check behavior
 * @param { String } studentParticipantId - Daily Participant session_id (also
 * called participant id in some Daily logs)
 * @param { String } studentPromotedUTC - [optional] ISO format timestamp of when 
 * student was promoted to Speaker
 * @returns { Boolean } indicating call success or failure
 */
export const recordStudentLessonInfo = async (
  lessonId,
  treatmentType,
  studentParticipantId,
  studentPromotedUTC = null,
) => {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    const response = await fetch(`${APIRootUrl}/speak/record_student_lesson_info`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonId,
        treatmentType,
        studentUserAgent: navigator?.userAgent,
        studentJoinedLessonUTC: new Date().toISOString(),
        studentSpeakAppVersion: version.version,
        studentParticipantId,
        studentPromotedUTC,
      }),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const result = await response.json();

    if ( !result.success ) {
      throw new Error("No success flag in return");
    }

    return result.success;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not record student lesson info",
    });
    return false;
  }
};

/**
 * For /join/identifier route with logged-in user. Returns useful information
 * about if lesson exists, if student is registered, if lesson has started or
 * ended, and the lesson info itself
 * 
 * @param { String } lessonIdentifier - idHash from join route
 * @returns 
 */
export const joinDrillWithAuth = async ( lessonIdentifier ) => {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    // Dispatch request to cloud functions
    const response = await fetch(`${APIRootUrl}/speak/join_drill_with_auth`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonIdHash: lessonIdentifier,
      }),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    // Extract response body
    const result = await response.json();

    // If we get a failure flag or don't have data, something is wrong
    if ( !result.success || !result.data ) {
      // Instead of throwing an error, just throw the status code to the catch block
      throw response.status;
    }

    // Set custom params to send to Google Analytics event
    const eventParams = { 
      student_type: result.data?.studentType ? result.data?.studentType : "",
      classes_attended: result.data?.classesAttended ? result.data?.classesAttended : 0};

    // If no previous classes attended, this is the first
    if ( result.data.classesAttended === 0 ) {
      logEvent(firebaseAnalytics, "first_drill_attended", eventParams);
    }
    // If one previous class attended, this is student's second class
    else if ( result.data.classesAttended === 1 ) {
      logEvent(firebaseAnalytics, "second_drill_attended", eventParams);
    }
    // Always log any attended drill as drill_attended event
    logEvent(firebaseAnalytics, "drill_attended", eventParams);

    return result.data;
  }
  catch ( errorCode ) {
    // Possible error codes:
    // 491 status means drill doesn't exist
    // 492 means student isn't registered for drill
    // 493 means drill is over (past lessonScheduledEndUTC)
    // Don't convert this to a talkka.error! We expect to hit this in the cases indicated by the above codes, so recording an ERROR logEvent here would be inappropriate
    console.error("Could not join lesson with auth");

    // Return object with errorCode to calling component
    // Caller can check for this and adjust accordingly
    return { errorCode };
  }
};

/**
 * Stamp studentLesson with ISO string indicating that student is present in
 * the drill now.
 * 
 * @param { String } lessonId
 * @returns boolean indicating success or failure
 */
export const recordStudentPresence = async ( lessonId ) => {
  try {
    // If required arg not passed, fail gracefully with warning message
    if ( !lessonId ) {
      console.warn("Must supply lessonId to recordStudentPresence");
      return;
    }

    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    // Dispatch request to cloud functions
    const response = await fetch(`${APIRootUrl}/speak/record_student_presence`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonId,
      }),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    // Extract response body
    const result = await response.json();

    // Check for call success
    if ( !result.success ) {
      throw new Error("No success flag in return");
    }

    return true;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not record student presence",
    });
    return false;
  }
};

// Register student as observer for lesson
export const registerStudentAsObserver = async (
  lessonIdHash, 
  lessonLocalizedStartTimeISO, 
  registeredAtLocalizedISO, 
  isFutureBooking, 
  bookingUtmData,
) => {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    const response = await fetch(`${APIRootUrl}/speak/register_student_as_observer`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonIdHash,
        lessonLocalizedStartTimeISO, 
        registeredAtLocalizedISO,
        isFutureBooking,
        bookingUtmData,
      }),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const result = await response.json();

    if ( !result.success ) {
      throw new Error("No success flag in return");
    }

    // Grab student type from result to pass for Google Analytics event logging
    const studentType = result.data?.studentType ? result.data?.studentType : "";

    // Set custom params to send to Google Analytics event
    const eventParams = {
      student_type: studentType,
      total_registrations: result.data?.registrationCount?.totalRegistrations ? 
        result.data?.registrationCount?.totalRegistrations : 0,
    };

    // Dispatch analytics events for benchmark registrations: First- and second-ever classes registered for, and first class registered for as a VIP
    if ( result.data?.registrationCount?.totalRegistrations === 1 ) {
      logEvent(firebaseAnalytics, "first_drill_booked", eventParams);
    }
    else if ( result.data?.registrationCount?.totalRegistrations === 2) {
      logEvent(firebaseAnalytics, "second_drill_booked", eventParams);
    }
    // First VIP class can also be first or second total class, so no else here
    if ( result.data?.registrationCount?.isFirstVIPRegistration ) {
      logEvent(firebaseAnalytics, "first_drill_booked_as_VIP", eventParams);
    }
    // Always log any booked drill as drill_booked event
    logEvent(firebaseAnalytics, "drill_booked", eventParams);

    return result.success;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not register student as observer",
    });
    return false;
  }
};

// Register student as speaker for lesson
export const registerStudentAsSpeaker = async (
  lessonIdHash, 
  lessonLocalizedStartTimeISO, 
  registeredAtLocalizedISO, 
  isFutureBooking, 
  bookingUtmData,
  associatedCampaignUtmValue = null,
) => {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    const response = await fetch(`${APIRootUrl}/speak/register_student_as_speaker`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonIdHash,
        lessonLocalizedStartTimeISO, 
        registeredAtLocalizedISO,
        isFutureBooking,
        bookingUtmData,
        associatedCampaignUtmValue,
      }),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const result = await response.json();

    if (!result.success || !result.data) {
      throw new Error("No success flag in return, or no data");
    }

    // Grab student type from result to pass for Google Analytics event logging
    const studentType = result.data?.studentType ? result.data?.studentType : "";

    // Set custom params to send to Google Analytics event
    const eventParams = {
      student_type: studentType,
      total_registrations: result.data?.registrationCount?.totalRegistrations ? 
        result.data?.registrationCount?.totalRegistrations : 0,
    };

    // Dispatch analytics events for benchmark registrations: First- and second-ever classes registered for, and first class registered for as a VIP
    if ( result.data?.registrationCount?.totalRegistrations === 1 ) {
      logEvent(firebaseAnalytics, "first_drill_booked", eventParams);
    }
    else if ( result.data?.registrationCount?.totalRegistrations === 2) {
      logEvent(firebaseAnalytics, "second_drill_booked", eventParams);
    }
    // First VIP class can also be first or second total class, so no else here
    if ( result.data?.registrationCount?.isFirstVIPRegistration ) {
      logEvent(firebaseAnalytics, "first_drill_booked_as_VIP", eventParams);
    }
    // Always log any booked drill as drill_booked event
    logEvent(firebaseAnalytics, "drill_booked", eventParams);

    return result.data;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not register student as speaker",
    });
    return false;
  }
};

// Cancel student lesson registration
export const cancelStudentLessonRegistration = async (lessonId) => {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    const response = await fetch(`${APIRootUrl}/speak/cancel_student_lesson_registration`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonId,
      }),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const result = await response.json();

    if ( !result.success ) {
      throw new Error("No success flag in return");
    }

    return result.success;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not cancel student lesson registration",
    });
    return false;
  }
};

/**
 * Determine if student is on latest version of the Speak App
 * 
 * @param { String } localVersion - of Speak App
 * @returns data that includes whether student is on latest version of Speak App
 */
export const getVersionCheck = async () => {
  try {

    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    // Send request to check app version
    const response = await fetch(`${APIRootUrl}/speak/get_app_version`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        clientVersion: version.version,
      }),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const result = await response.json();

    if (!result.success || !result.data) {
      throw new Error("No success flag in return, or no data");
    }

    return result.data;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not get version check",
    });
    return false;
  }
};

/**
 * Get lesson attendance details
 *
 * @param { string } lessonIdHash 
 * @returns Object with student's lesson attendance details
 */
export async function getStudentLessonAttendance(lessonId) {
  try {
    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    const body = {
      token,
      lessonId,
    };

    const response = await fetch(`${APIRootUrl}/speak/get_student_drill_attendance`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }

    const responseBody = await response.json();
    return responseBody.data;
  }
  catch (error) {
    conditionallyLog({
      error,
      message: "Could not get student drill attendance",
    });
    // TODO: Catching and immediately re-throwing doesn't provide any value, so change this! Handle the error appropriately (log it, retry, whatever) and return something useful to the caller
    throw new Error(error);
  }
}

/**
 * Get basic info needed to render Observe button for class happening right now
 *
 * @param { String } languageTarget - language that lessons are teaching
 * @returns Object with lesson info for lessons happening at this moment
 */
export const getRightNowLessonInfo = async ({ languageTarget }) => {
  try {
    const result = await asyncFetchResult({
      endpoint: "get_right_now_lesson_info",
      body: { languageTarget },
    });

    // Just return data - calling component responsible for parsing
    return result.data;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not get right now lesson info",
    });
    // Simulate return with no valid ongoing lesson
    return { rightNowLesson: false };
  }
};

/**
 * Get modules by level
 *
 * @param {Integer} level level of the modules to retrieve
 * @param {Array} fields (optional) fields to return from module files
 * @returns Array of modules
 */
export async function getModulesByLevelForTopicTree(level, wishlist, fields) {
  try {
    const body = {
      level,
      wishlist,
      fields,
    };

    const result = await asyncFetchResult({
      endpoint: "get_modules_for_topic_tree",
      body,
    });

    // Filter out welcome Drill
    const filteredResult = result?.data?.filter( 
      topic => topic.category !== "Welcome", 
    );
    
    // Return data we fetched
    return filteredResult;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not get completed modules by level for topic tree",
    });
    return false;
  }
}

/**
 * Get lessons for the topic tree
 *
 * @param {String} moduleId id of the module to retrieve lessons for
 * @param {Boolean} getAttendedOnly boolean flag to indcate if we only want attended lessons
 * @param {Integer} numOfLessons (optional) optional field to limit number of returned lessons
 * @param {Boolean} getPerfomance (toprional) boolean flag to indcate if we student performance
 * @param {Array} fields (optional) fields to return from module files
 * @returns Array of modules
 */
export async function getLessonsForTopicTree(moduleId, getAttendedOnly, numOfLessons, getPerformance = false, fields) {
  try {
    const body = {
      moduleId,
      getAttendedOnly,
      numOfLessons,
      getPerformance,
      fields,
    };

    const result = await asyncFetchResult({
      endpoint: "get_lessons_for_topic_tree",
      body,
    });

    // Return data we fetched
    return result.data;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not get lessons for topic tree",
    });
    return false;
  }
}

/**
 * Get lesson history for each module for the topic tree by module ids
 *
 * @param {Array} moduleIds array of ids of the modules to retrieve lessons for
 * @param {Boolean} getAttendedOnly boolean flag to indcate if we only want attended lessons
 * @param {Integer} numOfLessons (optional) optional field to limit number of returned lessons
 * @param {Boolean} getPerformance (optional) boolean flag to indcate if we student performance
 * @param {Array} fields (optional) fields to return from module files
 * @returns Array of modules
 */
export async function getModuleHistoryForTopicTree(moduleIds, getAttendedOnly, numOfLessons, getPerformance = false, fields) {
  try {
    const body = {
      moduleIds,
      getAttendedOnly,
      numOfLessons,
      getPerformance,
      fields,
    };

    const result = await asyncFetchResult({
      endpoint: "get_module_history_for_topic_tree",
      body,
    });

    // Return data we fetched
    return result.data;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not get module history for topic tree",
    });
    return false;
  }
}

/**
 * Get wishlist status for each module for the topic tree by module ids
 *
 * @param {Array} moduleIds array of ids of the modules to retrieve lessons for
 * @param {Array} fields (optional) fields to return from module files
 * @returns Array of modules
 */
export async function getWishlistForTopicTree(moduleIds, fields) {
  try {
    const body = {
      moduleIds,
      fields,
    };

    const result = await asyncFetchResult({
      endpoint: "get_wishlist_for_topic_tree",
      body: body,
    });

    // Return data we fetched
    return result.data;
  }
  catch (error) {
    conditionallyLog({
      error,
      message: "Could not get modules' wishlist status for student",
    });
    return false;
  }
}

/**
 * Record this student giving another student feedback via Celebrations
 * 
 * @param { String } feedbackGivenToStudentId - studentId of student receiving feedback
 * @param { String } feedbackType [optional] - Enum for type of feedback given. For now, is always THUMBSUP
 * @param { String } lessonId
 * @param { String } moduleId - of module active at the time feedback is given (not necessarily the same as the module on the lesson file)
 * @param { Number } slideDotComIndex
 * @returns 
 */
export const recordStudentFeedback = async ({
  feedbackGivenToStudentId,
  feedbackType = "THUMBSUP",
  lessonId,
  moduleId,
  slidesDotComIndex,
}) => {
  try {

    // If we don't have required fields, throw an error
    if (
      !feedbackGivenToStudentId
      || !lessonId
      || !moduleId
      // Slide index of 0 is technically valid
      || (!slidesDotComIndex && slidesDotComIndex !== 0)
    ) {
      throw new Error("Missing required argument to recordStudentFeedback");
    }

    const token = firebaseAuth.currentUser
      ? await getIdToken( firebaseAuth.currentUser )
      : null;

    const response = await fetch(`${APIRootUrl}/speak/record_student_feedback`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        feedbackGivenAtUTC: (new Date()).toISOString(),
        feedbackGivenToStudentId,
        feedbackType,
        lessonId,
        moduleId,
        slidesDotComIndex,
      }),
    });

    // Confirm that request succeeded before trying to parse it
    if ( !response.ok ) {
      const responseText = await response.text();
      throw new Error("status: " + response.status + ": " + responseText);
    }
    
    const result = await response.json();

    if ( !result.success ) {
      throw new Error("No success flag in return");
    }

    // Fire-and-forget function, return isn't used for anything
    return true;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not record student feedback",
    });
    return false;
  }
};

/**
 * Save event (typically error) info in Firestore. Only used with talkka.X
 * logging functions (see services/logging/talkka.js)
 *
 * @param { Object } logInfo - from talkka.X, contains info to save in
 * Firestore about the event we're recording
 * @returns { Boolean } indicating if call succeeded
 */
export const saveLogEvent = async (logInfo) => {

  // Pass logInfo directly through as body
  // WARNING: Changing the endpoint called by this function could result in infinite loops if there's an error during a saveLogEvent call. So if we do change the endpoint, also change the logic in the catch block of asyncFetchData to reflect that new endpoint
  const result = await asyncFetchResult({
    endpoint: "public/save_log_event",
    body: logInfo,
    // Dont retry this endpoint -- easy to get into an infinite loop, since most talkka.loggers call this function again
  });

  // Return boolean indicating success or failure of call
  return result.success;
};

/**
 * Get the conversation prompt to display on the NoInstructor screen for the given Drill
 * @param { String } lessonId of the lesson to get the prompt for
 * @returns { String } the conversation prompt for the given Drill
 */
export const getConversationPrompt = async ( lessonId ) => {

  // Hit endpoint to get this Drill's conversation prompt
  const result = await asyncFetchResult({
    endpoint: "get_conversation_prompt",
    body: { lessonId },
  });

  return result.data?.selectedPrompt;
};

/**
 * Get the studentLesson info to display on the ClassSummary page
 *
 * WARNING: This function does NOT retrieve the actual studentLesson object --
 * instead, it retrieves an object constructed from some studentLesson and
 * lesson data for use in the ClassSummary component
 *
 * @param { String } lessonIdHash of the lesson to get the info for
 * @returns { Object } with a variety of data from the studentLesson associated
 * with this lessonIdHash and student and its subcollections
 */
export const getStudentLessonDataForClassSummary = async ( lessonIdHash, fields ) => {

  // Hit endpoint to get studentLesson info
  const result = await asyncFetchResult({
    endpoint: "get_one_student_lesson",
    body: { 
      lessonIdHash,
      fields, 
    },
  });

  return result.data;
};

/**
 * Cancel a payment
 * @param { String } paymentId of the payment to cancel
 * @returns nothing
 */
export const cancelPayment = async (paymentId) => {
  const result = await asyncFetchResult({
    endpoint: "cancel_payment",
    body: {
      paymentId,
    },
  });

  return result.data;
};

/**
 * Prepare a SetDrillTopic payment
 * @param { String } lessonId of the lesson to purchase it for
 * @returns { String } paymentId of the new payment document
 */
export const prepareSetDrillTopicPayment = async (lessonId) => {
  const result = await asyncFetchResult({
    endpoint: "prepare_set_drill_topic_payment",
    body: {
      lessonId,
    },
  });

  return result.data;
};

/**
 * Get topics for a SetDrillTopic purchase
 * @param { String } lessonId of the lesson to suggest topics for
 * @returns { Array } of module information objects
 */
export const suggestTopicsForSelection = async (lessonId) => {
  const result = await asyncFetchResult({
    endpoint: "suggest_topics_for_selection",
    body: {
      lessonId,
    },
  });

  return result.data;
};

/**
 * Executes a SetDrillTopic payment by getting a Stripe PaymentIntent to handle
 * payment flow.
 * 
 * @param lessonId - ID of lesson to set topic for
 * @param moduleId - ID of module to set topic to
 * @param paymentId - ID of payment
 * @param hasFreeTopic - flag indicating if student can set topic for free
 * @return a Stripe PaymentIntent client secret to render the checkout page
 */
export const executeSetDrillTopicPayment = async (lessonId, moduleId, paymentId, hasFreeTopic) => {
  const result = await asyncFetchResult({
    endpoint: "execute_set_drill_topic_payment",
    body: {
      lessonId,
      moduleId,
      paymentId,
      hasFreeTopic,
    },
  });

  return result.data;
};

/**
 * Fetches a wordBundle by moduleId
 * 
 * @param { String } moduleId - id of module to fetch wordBundle for
 * @returns object representing wordBundle in Firestore
 */
export const asyncGetWordBundleIdByModuleId = async ( moduleId ) => {

  // Hit endpoint to get studentLesson info
  const result = await asyncFetchResult({
    endpoint: "get_word_bundle_id_by_module_id",
    body: { 
      moduleId,
    },
  });

  return result.data;
};

/**
 * Get the status of a payment
 * @param { String } stripePaymentIntentId of the payment
 * @returns { String } payment status
 */
export const getPaymentStatus = async (stripePaymentIntentId) => {
  const result = await asyncFetchResult({
    endpoint: "get_payment_status",
    body: {
      stripePaymentIntentId,
    },
  });

  return result.data;
};

/**
 * Save student feedback from a completed class
 */
export const saveClassFeedback = async ({
  feedbackObject,
  lessonId,
}) => {
  const result = await asyncFetchResult({
    endpoint: "save_class_feedback",
    body: {
      feedbackObject,
      lessonId,
      userAgent: window?.navigator?.userAgent,
    },
  });

  return result?.success;
};

/**
 * Retreive student's classFeedback for the given lessonId
 */
export const getClassFeedback = async ( lessonId ) => {
  const result = await asyncFetchResult({
    endpoint: "get_class_feedback",
    body: { lessonId },
  });

  return result?.data?.classFeedback;
};

/**
 * Get the campaign file contents for a campaign given its associatedUtmValue and use it to
 * update a student file
 */
export const checkCampaignAndUpdateStudent = async ( associatedUtmValue ) => {
  const result = await asyncFetchResult({
    endpoint: "check_campaign_and_update_student",
    body: { associatedUtmValue },
  });

  return result?.data;
};

/**
 * Get the campaign info of a single campaign
 *
 * @param { String } associatedUtmValue of the campaign to get the info for
 * @returns { Object } with a variety of data from the campaign associated with this associatedUtmValue
 */
export const getOneCampaign = async ( associatedUtmValue, fields ) => {

  // Hit endpoint to get campaign info
  const result = await asyncFetchResult({
    endpoint: "get_one_campaign",
    body: { 
      associatedUtmValue,
      fields, 
    },
  });

  return result.data;
};

/**
 * Get the daily Pro Drill count of a given student
 *
 * @returns { Object } with a variety of data from the campaign associated with this associatedUtmValue
 */
export const getDailyProDrillCount = async ( startTimeUTC, endTimeUTC) => {

  // Hit endpoint to get the daily Pro Drill count
  const result = await asyncFetchResult({
    endpoint: "get_daily_pro_drill_count",
    body: { 
      startTimeUTC,
      endTimeUTC, 
    },
  });

  return result.data;
};

/**
 * Generate a story for a specific student
 * 
 * @param {string} prompt - Prompt to generate story with
 * @returns {Object} Story data including title, characters, and story text
 */

export const generateStory = async ( prompt ) => {
  const result = await asyncFetchResult({
    endpoint: "generate_story",
    body: { 
      prompt, 
    },
  });

  return result.data;
};


/**
 * Synthesize text to speech
 * @param {Array} textParts - Array of text parts to synthesize
 * @returns {Blob} - Synthesized speech as a Blob
 */
export const synthesizeTextToSpeech = async (textParts) => {
  try {
    const result = await asyncFetchResult({
      endpoint: "synthesize_text",
      body: { textParts },
      responseType: "blob",
    });

    if (!result.success) {
      throw new Error("Failed to synthesize speech");
    }

    return result.data;
  }
  catch (error) {
    talkka.error("Error synthesizing speech:", error);
    return false;
  }
};

/**
 * Get all the fields of a story and parse the story text
 *
 * @param { String } storyId of the story to get the info for
 * @param { Number } englishNotation English notation level
 * @returns { Object } Story data
 */
export const getStoryContent = async (storyId, englishNotation) => {
  try {
    const result = await asyncFetchResult({
      endpoint: "get_story_content",
      body: { 
        storyId,
        englishNotation,
      },
    });

    if (!result.success) {
      throw new Error(result.error || "Failed to get story content");
    }

    if (!result.data || !result.data.storyText) {
      throw new Error("Invalid story content received");
    }

    return result.data;
  }
  catch (error) {
    console.error("Error getting story content:", error.message);
    throw error;
  }
};

/**
 * Get stories for the story tree
 *
 * @param {String} storyId id of the story to retrieve lessons for

 * @param {Array} fields (optional) fields to return from module files
 * @returns Array of modules
 */
export async function getStoriesForStoryTree() {
  try {
    const body = {
    };

    const result = await asyncFetchResult({
      endpoint: "get_stories_for_story_tree",
      body,
    });

    // Return data we fetched
    return result.data;
  }
  catch ( error ) {
    conditionallyLog({
      error,
      message: "Could not get stories for story tree",
    });
    return false;
  }
}
