import React, { useState } from "react";
import "./VersionHistory.css";

/**
 * An array of version objects, each containing version details such as version number, date, and changes.
 */
export const versions = [
  {
    version: "v0.6.0",
    date: "12.29.2024",
    changes: [
      "Stories now load set to be read in 100% Spanish.",
      "Created a centralized voice mapping system that allows for easy voice assignment to characters and resolves conflicts if two characters have the same voice.",
      "Improved female voices to sound more natural and less robotic.",
      "Integrated the same high-quality voices used for Stories to be used in the Study Vocab section for each Drill Topic.",
    ],
  },
  {
    version: "v0.5.1",
    date: "12.19.2024",
    changes: [
      "Milestone achieved: Story 3 (now available) was the first story launched without the need for a tech deployment. This lays groundwork for fully autonomous story creation.",
      "Fixed how new stories are displayed in the Story Tree.",
      "Fixed an issue where the scrollbar added for Stories was showing up during Drill, blocking some key information to students.",
      "Added logic where free students can see the Story Tree, but a purchase Pro modal is seen when they click to listen.",
    ],
  },
  {
    version: "v0.5.0",
    date: "12.18.2024",
    changes: [
      "Added first iteration of transcript viewing (high demand feature).",
      "Updated Stage 1 Translation Mix (the most English option on the slider) to first read the entire Spanish sentence without interruption, then to break the sentences into smaller segments followed by the English translations.",
      <>
        Playback Speed Slider:
        <ul className="version-change-sub-list">
          <li>Changed slowest speed option from 0.5x to 0.6x as it sounded as if voices were 'underwater' at the slowest speed.</li>
          <li>Decreased step size from 0.1x to 0.05x to give listeners finer control.</li>
        </ul>
      </>,
    ],
  },
  {
    version: "v0.4.0",
    date: "12.17.2024",
    changes: [
      "Implemented foundational backend architecture to support the generation, saving, and retrieval of personalized stories. All stories are now securely stored in the database and accessible to users with the appropriate permissions.",
      "New Story Tree page to view all stories.",
      "Version History dropdown added to Story Tree.",
      "Made Story 1 accessible to re-listen to and adapted it to include the four progressive learning stages.",
    ],
  },
  {
    version: "v0.3.0",
    date: "12.13.2024",
    changes: [
      "Includes new story using phrases from the drill topic: Taking a taxi.",
      "Story Database: Added a dropdown for students to see details about past and current stories.",
      "Announcement Bar: Added dynamic text that can be seen throughout the app that alerts students when there is new Story content or features.",
      <>
        New Translation Mix feature: AI-powered translation system with four progressive learning stages:
        <ul className="version-change-sub-list">
          <li>Stage 1: Long Spanish sentences broken into smaller segments, each followed by English translation.</li>
          <li>Stage 2: Complete Spanish sentences followed by full English translations.</li>
          <li>Stage 3: Only complex Spanish words translated to English.</li>
          <li>Stage 4: Spanish-only mode with no translations.</li>
        </ul>
      </>,
    ],
  },
  {
    version: "v0.2.0",
    date: "12.11.2024",
    changes: [
      "Added: Standard header logo, back button, and menu.",
      "Standardized: Slider bars for language mix.",
      "Fixed: Formatting for 'Take Quiz' button where it loaded left aligned.",
    ],
  },
  {
    version: "v0.1.0",
    date: "12.10.2024",
    changes: [
      "Voice Fix: Switched to Google Neural2 voices to resolve audio playback issues (previous Journey voices were hitting API limits).",
      "Added: Fast forward/rewind (3s) buttons.",
      "Added: Playback speed control.",
      "Removed: Stop button.",
      "Added: Version history tracker so you can see how feedback has been incorporated.",
      "Added: Related Drill Topic link.",
      "Enhanced: Readability on mobile devices.",
    ],
  },
  {
    version: "v0.0.0",
    date: "12.08.2024 - Initial Release",
    changes: [
      "Story with realistic AI voices engaging in conversation about fictional village Atlangatepec.",
      "Basic audio controls: Start, Stop, Pause.",
      "Language Mix: Ability to toggle three levels of English translation: None, Half, and All.",
    ],
  },
];

/**
 * The most recent version of the stories.
 */
export const mostRecentStoriesVersion = versions[0].version;

/**
 * VersionHistory Component
 * Displays the version history of the application.
 * 
 * @returns {JSX.Element} The rendered component
 */
const VersionHistory = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="version-history-container">
      <h2 className="version-history-label">
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ cursor: "pointer" }}
        >
          Learn more about Stories {mostRecentStoriesVersion}
          {isExpanded ? " ▼" : " ▶"}
        </div>
      </h2>
      {isExpanded && (
        <div className="version-details-container">
          <div className="details-text">
            Beepboop Stories uses storytelling to enhance listening skills and reinforce what is learned in Drills.
            <a href="https://friends.beepboop.us/p/introducing-beepboop-stories-v000" target="_blank" rel="noopener noreferrer">
              &gt; Learn More
            </a>
          </div>
          <h2 className="version-history-label">Version History</h2>
          {versions.map((ver) => (
            <div key={ver.version} className="version-entry-template">
              <div className="title">{ver.date} - {ver.version}:</div>
              <ul className="changes-list">
                {ver.changes.map((change, index) => (
                  <li key={index} className="change-item">
                    {change}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VersionHistory;
