import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { version_string } from "../../App.js";

import textlogo from "../../assets/beepboopTextLogo.png";

import config from "../../configs/activebuildconfig.json";
const {
  RELEASE_NOTES_URL,
  PURCHASE_URL_MAP,
} = config;

import { getCurrentUser, UserContext } from "../../context/UserContext";

import BrowserBlocker from "../BrowserBlocker";
import VersionCheck from "../VersionCheck";

import Hamburger from "./Hamburger";
import MobileNavbarMenu from "./MobileNavbarMenu";
import NavbarQuoteArea from "./NavbarQuoteArea";

import "./mobilenavbar.scss";
import { getMostRecentlyRedeemedCampaign, runCampaignCheck } from "../../services/campaignHelpers.js";
import { getDateObjFromOldFormatVIPExpirationDate } from "../../services/time.js";

import { mostRecentStoriesVersion } from "../../pages/StoryTreePage/VersionHistory";

/**
 * Component for mobile-optimized header, new version
 * 
 * @param {Object} children - contents of the page
 * @param {string} returnPathname - Link to return to in back section of header
 * @param {string} returnFrom - name of page that should be passed as a "from" state
 * @param {string} returnMessage - Message to display linked to returnPathname
 * @param {string} pageName - Name of the page to display in center of header
 * @param {boolean} displayNavBar - determines whether to displayNavBar and Buy Pro button
 * @param {boolean} hideNonNavBarItems - determines whether to anything inside of the navbar (Go VIP section, Book a Spanish Class Now link, version)
 * @param {boolean} hideCampaignsBar - determines whether display campaigns bar, overriding other options
 * 
 */
const MobileNavbar = ({ 
  children, 
  returnPathname, 
  returnFrom, 
  returnMessage, 
  pageName, 
  displayNavBar = true, 
  displayGoVIPButton = true, 
  returnLevel = "",
  displayAnnouncementBar = true,
  hideNonNavBarItems = false,
  hideCampaignsBar = false,
}) => {

  /*
   * HOOKS
   */

  const { t } = useTranslation();

  const { updateCurrentUser } = useContext( UserContext );

  const user = getCurrentUser();
  const { languageFluent, redeemedCampaigns, student_type } = user;

  /*
   * CONSTANT
   */

  const utmLink = PURCHASE_URL_MAP[languageFluent] + "?utm_source=studentportal&utm_medium=bottompage&utm_campaign=purchase-" + pageName?.toLowerCase() + "page";
  // const utmLinkAnnouncementBarNonVIP = PURCHASE_URL_MAP[languageFluent] + "?utm_source=studentportal&utm_medium=toppage&utm_campaign=purchase-announcement-NonVIP";

  /*
   * STATE
   */

  const [showMenu, setShowMenu] = useState(false);
  const [showGoVIPButton, setShowGoVIPButton] = useState(false);
  const [studentEmail, setStudentEmail] = useState("");
  const [studentName, setStudentName] = useState("");
  const [isLoadingCampaignData, setIsLoadingCampaignData] = useState(true);
  const [campaignData, setCampaignData] = useState(false);
  const [mostRecentRedeemedCampaignUtm, setMostRecentRedeemedCampaignUtm] = useState("");
  const [mostRecentNewExpFromCampaign, setMostRecentNewExpFromCampaign] = useState("");

  /*
   * USE EFFECT
   */

  // Replacement for old fetchStudent helper - when user info loads from context, set appropriate state
  useEffect(() => {

    if ( user ) {
      setShowGoVIPButton(
        displayGoVIPButton
        && (user.student_type === "ACTIVE" || user.student_type === "TRIAL"),
      );

      setStudentName(user.first_name);
      setStudentEmail(user.email);
    }

  }, [
    displayGoVIPButton,
    user,
  ]);

  // On initial load, check for campaign data and for previously redeemed campaign
  useEffect(() => {
    asyncFetchData();

    // if the user has redeemed campaigns, grab the utm of the most recently claimed one
    if (user.redeemedCampaigns) {
      const { mostRecentUtmValue, mostRecentNewStudentVipExpiration } = getMostRecentlyRedeemedCampaign(user.redeemedCampaigns);
      // save the recent utm value associated with the most recently redeemed campaign
      if (mostRecentUtmValue !== "") {
        setMostRecentRedeemedCampaignUtm(mostRecentUtmValue);
        setMostRecentNewExpFromCampaign(mostRecentNewStudentVipExpiration);
      }
    }
  }, []); 

  // When campaignData object is updated, update user context too 
  useEffect(() => {
    // we use studentNewVipExpiration having a value as an indication of student redeeming campaign
    if (campaignData && campaignData.studentNewVipExpiration !== "") {
      const redeemedCampaignsUpdated = redeemedCampaigns ? redeemedCampaigns : {};
      redeemedCampaignsUpdated[campaignData.associatedUtmValue] = {
        campaignRedeemedAtUTC: campaignData.campaignRedeemedAtUTC,
        studentNewVipExpiration: campaignData.studentNewVipExpiration,
      };
    
      updateCurrentUser({
        redeemedCampaigns: {...redeemedCampaignsUpdated},
        // update student type in user context if user wasn't VIP and now is
        student_type: !["BRONZE", "SILVER", "GOLD", "PLATINUM"].includes(student_type) ? "GOLD" : student_type,
      });
    }
  }, [ campaignData ]);

  // initial asyncFetchData call with initialization data for studentLesson
  async function asyncFetchData() {
    try {
      // retrieve campaign info
      const fetchedCampaignData = await runCampaignCheck(location);
      setCampaignData(fetchedCampaignData);
    }
    catch (error) {
      talkka.error("Error fetching content for campaign", error);
    }

    setIsLoadingCampaignData(false);
  }

  /*
   * HELPERS
   */

  // Helper to close menu -- passed down to MobileNavbarMenu to execute on click
  const toggleMenu = () => setShowMenu( prev => !prev );

  /*
   * RENDER
   */

  return (
    <VersionCheck>
      <div className={"navbarWrapper" + (showMenu ? " navbarWrapperOpen" : "")}>
        <BrowserBlocker />
        <div className="navbarMainheaderContainer">
          <div className="navbarMainheader">
            <div>
              {returnMessage === "topictree"
                ? (
                  <Link
                    className={ "returnLinkOuter" }
                    to={{
                      pathname: "/topictree",
                      search: "?level=" + returnLevel,
                    }}
                    state={{
                      from: returnFrom ? returnFrom : "home",
                      level: returnLevel,
                    }}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} size="1x"/>
                    <div className="returnLink">
                      { t("components.mobileNavBar.returnTo") }
                      <br/>
                      Topic Tree
                    </div>
                  </Link>
                )
                : (
                  <Link
                    className={"returnLinkOuter" + (pageName.toLowerCase() === "home" || returnMessage === "" || !displayNavBar ? " hideReturnLinkOuter" : "")} 
                    to={ `${returnPathname?.startsWith("/") ? "" : "/"}${returnPathname ? returnPathname : "topictree"}` }
                    state={{
                      from: returnFrom ? returnFrom : "topictree",
                      level: returnLevel,
                    }}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} size="1x"/>
                    <div className="returnLink">
                      { t("components.mobileNavBar.returnTo") }
                      <br/>
                      {returnMessage
                        ? t(`pageNames.${returnMessage.charAt(0).toUpperCase() + returnMessage.substring(1)}`)
                        : "Previous"
                      }
                    </div>
                  </Link>
                )
              }  
            </div>
            <div className="centerText">
              <div>
                <Link
                  to="/home"
                  state={{ from: "home" }}
                  onClick={
                    // If student clicks on logo while on homepage, reload!
                    () => {
                      if ( window.location.pathname === "/home" ) {
                        window.location.reload();
                      }
                    }
                  }
                >
                  <img
                    className="textLogo"
                    src={textlogo}
                    title="Beepboop Speak App"
                    alt="Beepboop Speak App"
                  />
                </Link>
              </div>
            </div>
            <div className={"menuContainer" + (displayNavBar ? "" : " hideMenu")}>
              <div className="hamburgerAndText">
                <Hamburger
                  open={ showMenu }
                  toggleMenu={ toggleMenu }
                  displayHamburger={ displayNavBar }
                />
                <div className="menuText">
                  { t("components.mobileNavBar.menu") }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"navbarSubheader" + (pageName === "" ? " hideNavBarSubheader" : "")}>
          { t(`pageNames.${pageName}`) }
        </div>
        {((displayNavBar && !hideNonNavBarItems) || !hideCampaignsBar) &&
        <>
          {
          // if we have campaign data and a new vip expiration was set, the user just redeemed the campaign benefit
            (!isLoadingCampaignData 
            && campaignData
            && campaignData.studentNewVipExpiration !== "") 
              ? 
              <div className="campaignAnnouncementBarContainer">
                <div className="campaignAnnouncementBar">
                Congrats! You're a Pro until {campaignData.studentNewVipExpiration}, so be sure to book a Drill as a Pro while you have the chance!
                </div>
              </div>
              : (
                (mostRecentRedeemedCampaignUtm !== ""
                // Don't display bar if we're past the VIP expiration date, assumed to expire at 14:00 UTC
                && getDateObjFromOldFormatVIPExpirationDate(mostRecentNewExpFromCampaign !== "" ? mostRecentNewExpFromCampaign : user.VIP_expiration).toISOString() >= (new Date()).toISOString()) && 
                <div className="campaignAnnouncementBarContainer">
                  <div className="campaignAnnouncementBar">
                        Your Pro treatment lasts until {mostRecentNewExpFromCampaign !== "" ? mostRecentNewExpFromCampaign : user.VIP_expiration}
                  </div>
                </div>
              )
          }
        </>
        }
        {// if announcement bar encourages bookings, hide on the booking page 
          (displayAnnouncementBar
            && !window?.location?.href?.includes("/bookingpagenameifwantingtohide")
            && !hideNonNavBarItems
          )
            ? (
              // Banner rendered for all native Spanish speakers
              <div className="announcementBarSubheader">
                { languageFluent === "es" ? 
                  <Link
                    className="announcementMessageContainer"
                    to="/book"
                  >
                    <span className="announcementContentsLink">
                      { t("components.mobileNavBar.nativeSpanishMessage") }
                    </span>
                  </Link>
                  :
                  (
                    // Banner rendered for native English speakers (VIP)
                    ["BRONZE", "SILVER", "GOLD", "PLATINUM"].includes(student_type) ? 
                      <Link
                        className="announcementMessageContainer"
                        to="/storytree"
                      >
                        <span className="announcementContentsLink">
                          { t("components.mobileNavBar.announcementPro") + " " + mostRecentStoriesVersion }
                        </span>
                      </Link>
                      : 
                      // Banner rendered for native English speakers (non-VIP)
                      <Link
                        className="announcementMessageContainer"
                        to="/storytree"
                      >
                        <span className="announcementContentsLink">
                          { t("components.mobileNavBar.announcementPro") + " " + mostRecentStoriesVersion }
                        </span>
                      </Link>
                  )
                }
                
              </div>
            )
            : null
        }
        {/*// For now, hide announcement bar from Spanish speakers
          (displayAnnouncementBar && languageFluent !== "es" && showGoVIPButton)
          &&
            (<div className="announcementBarSubheader">
              <a
                className="announcementMessageContainer"
                target="_blank"
                href={ utmLinkAnnouncementBarNonVIP }
              >
                📚 Summer is ending, let’s hit the books! 📚<br />
                <span className="announcementContentsLink">15% off any VIP package with code BACK2SCHOOL.</span><br />
                Valid through August 31
              </a>
            </div>)
      */}
        <MobileNavbarMenu
          pageName={ pageName }
          returnFrom={ returnFrom }
          showGoVIPButton={ showGoVIPButton }
          showMenu={ showMenu }
          studentEmail= { studentEmail }
          studentName= { studentName }
        />
        { children }
        {showGoVIPButton && displayNavBar && languageFluent !== "es" && !hideNonNavBarItems
          ?
          <>
            <div className="verticalSpacer"></div>
            <div className="bottomVIPContainer">
              <div className="bottomGoVIPContainer">
                <a 
                  className="genButton goVIPButton" 
                  target="_blank" 
                  rel="noreferrer noopener" 
                  href={utmLink}>Buy Pro</a>
              </div>
              <NavbarQuoteArea />
            </div>
          </>
          : null
        }
        {
          !hideNonNavBarItems &&
          <div className="versionWrapper">
            <a className="versionString" href={RELEASE_NOTES_URL} target="_blank">
              {version_string}
            </a>
          </div>
        }
      </div>
    </VersionCheck>
  );
};

export default MobileNavbar;
